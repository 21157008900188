/*==========================
    Service Area
===========================*/

.service {
    padding: 20px 0;

    .icon {
        img {
            margin-bottom: 34px;
            @media #{$sm-layout} {
                margin-bottom: 14px;
                height: 53px;
            }
            @media #{$large-mobile} {
                margin-bottom: 13px;
                height: 45px;
            }
        }
    }

    .content {
        h4 {
            &.title {
                margin-bottom: 20px;
                font-weight: 400;
                font-size: 24px;
                @media #{$sm-layout} {
                    margin-bottom: 12px;
                    font-weight: 400;
                    font-size: 20px;
                }
                @media #{$large-mobile} {
                    margin-bottom: 9px;
                    font-size: 18px;
                }
            }
        }
        p {
            opacity: 0.75;
            font-weight: 300;
        }
    }

    &.service__style--2 {
        padding: 30px 35px;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        border-radius: 10px;
        background: #191919;
        height: 100%;
        min-height: 450px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @media #{$laptop-device} {
            padding: 30px 28px;
        }
        @media #{$md-layout} {
            padding: 30px 25px;
        }

        .icon {
            margin-bottom: 23px;
            flex: 0 0 auto;
            font-size: 54px;
            font-weight: 400;
            display: inline-flex;
            color: $theme-color;
            @extend %transition;

            @media #{$md-layout} {
                margin-bottom: 11px;
            }
            @media #{$large-mobile} {
                color: #ffffff;
            }

            svg {
                stroke-width: 1 !important;
            }
        }

        .content {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;

            h3.title {
                margin-bottom: 19px;
                font-weight: 500;
                line-height: 1.4;
                flex: 0 0 auto;
                @extend %transition;
                color: #ffffff;

                @media #{$laptop-device} {
                    font-size: 19px;
                }
                @media #{$sm-layout} {
                    font-size: 20px;
                }
                @media #{$md-layout} {
                    margin-bottom: 8px;
                }
            }

            p {
                @extend %transition;
                color: rgba(255,255,255,0.75);
                margin-bottom: 8px;
                line-height: 1.6;
                flex: 0 0 auto;

                &:empty {
                    display: none;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &:hover {
            box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
            transform: translateY(-5px);
        }

        &.service-left-align {
            display: flex;
            padding: 50px 24px;

            .icon {
                padding-right: 20px;
            }
        }
    }

    &.service__style--4 {
        padding: 53px 40px;
        z-index: 2;
        border-radius: 10px;
        position: relative;
        @extend %transition;
        background: #191919;
        height: 100%;
        cursor: pointer;
        @media #{$laptop-device} {
            padding: 36px 25px;
        }
        @media #{$lg-layout} {
            padding: 25px 20px;
        }
        @media #{$md-layout} {
            padding: 30px 25px;
        }
        @media #{$sm-layout} {
            padding: 25px 20px;
        }
        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background-image: -webkit-gradient(linear,left top,right top,from(#f61b10),to(#ef0963));
            background-image: -webkit-linear-gradient(left,#f61b10,#ef0963);
            background-image: linear-gradient(90deg,#f61b10,#ef0963);
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            border-radius: 10px;
            @extend %transition;
            @media #{$large-mobile} {
                opacity: 1;
                visibility: visible;
            }
        }

        .icon {
            display: inline-flex;
            font-size: 54px;
            font-weight: 400;
            margin-bottom: 23px;
            display: inline-flex;
            color: $theme-color;
            @extend %transition;
            @media #{$sm-layout} {
                margin-bottom: 18px;
            }
            @media #{$large-mobile} {
                color: #fff;
            }
            svg {
                stroke-width: 1 !important;
            }
        }
        .content {
            h3 {
                &.title {
                    margin-bottom: 19px;
                    font-weight: 500;
                    font-size: 22px;
                    @extend %transition;
                    @media #{$laptop-device} {
                        margin-bottom: 12px;
                        font-size: 20px;
                    }
                    @media #{$large-mobile} {
                        color: #fff;
                    }
                    @media #{$sm-layout} {
                        margin-bottom: 10px;
                        font-size: 18px;
                    }
                }
            }
            p {
                color: rgba(29,29,36,.75);
                @extend %transition;
                @media #{$large-mobile} {
                    color: #fff;
                }
            }
        }
        &:hover {
            box-shadow: 0 10px 25px 10px rgba(0,0,0,.1);
            -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
            &::before {
                opacity: 1;
                visibility: visible;
            }
            .icon {
                color: #fff;
            }
            .content {
                h3 {
                    &.title {
                        color: #fff;
                    }
                }
                p {
                    color: #fff;
                }
            }
        }
    }
}

/* ---------------------------
    Standard Service
-------------------------------*/
.standard-service {
    .thumbnai {
        img {
            border-radius: 5px;
        }
    }
    .content{
        h3 {
            font-size: 19px;
            margin-top: 30px;
            margin-bottom: 10px;
            font-weight: 600;
            a {
                @extend %transition;
                color: $heading-color;
                &:hover {
                    color: $theme-color;
                }
            }
        }
        p {
            font-size: 15px;
            line-height: 24px;
        }
        a {

        }
    }
}

.text-center,
.text-left {
    .service.service__style--2 {
        padding: 60px 45px;
        @media #{$sm-layout}, #{$lg-layout}, #{$md-layout} {
            padding: 30px 14px;
        }
    }
}

/* Service White */
.service-white {
    .service__style--1 {
        z-index: 2;
        .content {
            h4 {
                &.title {
                    color: #c6c9d8;
                }
            }
            p {
                color: #c6c9d8;
            }
        }
    }
}

/*---------------------------
    Paralax Service Style
-----------------------------*/

.rn-paralax-service {
    .service__style--2 {
        z-index: 2;
        padding: 40px;
        background: rgba(255,255,255, 0.3);
        margin-top: 40px;
        overflow: hidden;
        border: 2px solid transparent;
        min-height: 340px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        @media #{$sm-layout} {
            padding: 30px 20px;
            background: transparent;
            margin-top: 30px;
            min-height: 300px;
        }

        .icon {
            color: #ffffff;
        }

        .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            min-height: 260px;
            padding: 15px 0;
            gap: 15px;

            h3 {
                margin: 0;
                min-height: 28px;
                color: #ffffff;
                &.title {
                    font-size: 19px;
                    margin-bottom: 8px;
                }
                &:empty {
                    min-height: 28px;
                    display: block;
                }
            }

            p {
                margin: 0;
                color: rgba(255,255,255, 0.75);
                min-height: 24px;
                line-height: 1.5;
                &:last-child {
                    margin-bottom: 0;
                }
                &:empty {
                    min-height: 24px;
                    display: block;
                    &::after {
                        content: "\00a0";
                        display: block;
                    }
                }
            }
        }

        &:hover {
            border: 2px solid $theme-color;
            &::before {
                border-radius: 0;
            }
        }
    }
}

/* Add styles for the concert card wrapper */
a[href*="events"] {
    display: block;
    height: 100%;
    text-decoration: none;

    > div {
        height: 100%;
    }
}

/* Creative Agency Service */
.creative-service-wrapper {
    .row {
        &.creative-service {
            a {
                display: flex;
                height: 100%;
            }
            .service {
                &.service__style--2 {
                    margin-top: 30px;
                    background: #f6f6f6;
                }
            }
        }
    }
}

.service-one-wrapper {
    @media #{$md-layout} {
        margin: 0 -40px;
    }
}
