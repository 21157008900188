// Concert Grid Layout
.concert-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 350px);
    gap: 30px;
    justify-content: center;
    padding: 30px;
    margin: 0 auto;
    max-width: 1200px;

    @media #{$laptop-device} {
        grid-template-columns: repeat(auto-fit, 300px);
        gap: 25px;
        padding: 25px;
    }

    @media #{$md-layout} {
        grid-template-columns: repeat(auto-fit, 280px);
        gap: 20px;
        padding: 20px;
    }

    @media #{$sm-layout} {
        grid-template-columns: 280px;
        padding: 15px;
    }
}

// Concert Card Styles
.concert-card {
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 450px;
    background: #191919;
    border-radius: 10px;
    padding: 40px;
    transition: transform 0.3s ease;
    box-sizing: border-box;
    text-decoration: none;
    color: inherit;

    &:hover {
        transform: translateY(-5px);
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 15px;
        height: 100%;

        .date-row {
            display: flex;
            align-items: center;
            gap: 10px;

            .calendar-icon {
                color: #f9004d;
                font-size: 20px;
                min-width: 20px;
            }
        }

        h3, p {
            margin: 0;
            padding: 0;
            width: 100%;
            word-wrap: break-word;
            overflow-wrap: break-word;
            min-height: 28px;
            display: flex;
            align-items: center;
        }

        h3 {
            color: #ffffff;
            font-size: 20px;
            line-height: 1.4;
            min-height: 32px;

            &:first-child {
                color: #c6c9d8;
            }

            &:empty::after {
                content: "\00a0";
                display: block;
                min-height: inherit;
            }
        }

        p {
            color: rgba(255, 255, 255, 0.75);
            line-height: 1.5;

            &:empty::after {
                content: "\00a0";
                display: block;
                min-height: inherit;
            }
        }
    }

    @media #{$laptop-device} {
        width: 300px;
        height: 400px;
        padding: 30px;
    }

    @media #{$md-layout}, #{$sm-layout} {
        width: 280px;
        height: 400px;
        padding: 25px;
    }
}
